import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import {
  CollapsibleGroup,
} from './Types'
import Context from './CollapsibleContext'

function useCollabsible(groupId: string) {
  const {
    collapsibles,
    uncollapse,
    ensureGroup,
  } = useContext(Context)

  //
  // Update fix:
  // Since uncollapse will always be a new function, we're
  // having react component updating issues with leads to wrong
  // behavior. To fix this, we're updating a ref to prevent
  // component updates:
  const uncollapseRef = useRef<null | typeof uncollapse>(uncollapse)
  useEffect(() => {
    uncollapseRef.current = uncollapse
    return function cleanup() {
      if (uncollapseRef?.current) uncollapseRef.current = null
    }
  }, [uncollapse])

  //
  // Ensure group data
  useEffect(() => {
    ensureGroup(groupId)
  }, [ensureGroup, groupId])

  //
  // Prepare collapsible
  const group = useMemo(() => {
    const g = collapsibles[groupId]

    return g && isObject(g) ? g : {} as CollapsibleGroup
  }, [collapsibles, groupId])

  //
  // Get uncollapsed items
  const uncollapsedItems = useMemo(
    () => group?.itemsOpen || [],
    [group?.itemsOpen]
  )

  //
  // Toggle function
  const uncollapseItem = useCallback((itemId: string) => {
    if (!isFunction(uncollapseRef?.current)) return
    uncollapseRef.current(groupId, itemId)
  }, [groupId])

  //
  // Return
  return useMemo(() => ({
    id: groupId,
    uncollapsedItems,
    uncollapse: uncollapseItem,
  }), [groupId, uncollapseItem, uncollapsedItems])
}

export default useCollabsible
