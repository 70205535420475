import classNames from 'classnames'
import { CSSProperties, FC, ReactNode, useMemo } from 'react'

import Styles from './Card.module.sass'

export interface CardProps {
  /**
   * Assign additional css classes
   */
  className?: string;

  /**
   * Content components
   */
  children?: ReactNode;

  /**
   * Handle the overflow behavior
   */
  overflow?: CSSProperties['overflow']
}

const Card: FC<CardProps> = ({
  className,
  children,
  overflow = 'hidden',
}) => {
  const clsOverflow = useMemo(() => classNames({
    [Styles.overflowAuto]: overflow === 'auto',
    [Styles.overflowVisible]: overflow === 'visible',
    [Styles.overflowHidden]: overflow === 'hidden',
    [Styles.overflowClip]: overflow === 'clip',
    [Styles.overflowScroll]: overflow === 'scroll',
  }), [overflow])

  return (
    <div className={`${Styles.Container} ${clsOverflow} ${className || ''}`}>
      {children}
    </div>
  )
}

export default Card
