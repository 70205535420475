import { Field } from 'atoms/Form'
import { ComponentType, FC } from 'react'

import type { FieldProps } from 'rc-field-form/lib/Field'
import type { ComponentFieldBaseProps } from './Types'

export interface WithFieldProps extends ComponentFieldBaseProps {
  fieldProps?: FieldProps;
}

function withField<ComponentProps extends ComponentFieldBaseProps>(
  Component: ComponentType<ComponentProps>
) {
  const WithField: FC<Omit<
    ComponentProps,
    'value' | 'onChange'
  > & WithFieldProps> = ({
    name,
    fieldProps,
    ...restProps
  }) => (
    <Field name={name} {...fieldProps}>
      <Component
        {...(restProps as ComponentProps)}
        name={name}
      />
    </Field>
  )

  // Set the display name as composition of name
  // and wrapped components name
  const wrappedDisplayName = Component.displayName || Component.name || 'Component'
  WithField.displayName = `WithField(${wrappedDisplayName})`

  // Return wrapper
  return WithField
}

export default withField
