'use client'

import compact from 'lodash/compact'
import includes from 'lodash/includes'
import isObject from 'lodash/isObject'
import without from 'lodash/without'
import { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import isPresent from '@vayapin/utils/isPresent'

import { DEFAULT_DATA, Provider } from './CollapsibleContext'
import { CollapsibleContext, CollapsibleGroup } from './Types'

interface CollapsibleContextProviderProps {
  children: ReactNode
}

const CollapsibleContextProvider: FC<CollapsibleContextProviderProps> = ({
  children
}) => {
  const [collapsibles, setCollapsibles] = useState(
    DEFAULT_DATA.collapsibles
  )

  //
  // Toggle an item
  const toggle = useCallback((groupId: string, itemId: string) => {
    if (!isPresent(groupId)) return
    if (!isPresent(itemId)) return

    setCollapsibles((d) => {
      const group: CollapsibleGroup = d[groupId]
      if (!group || !isObject(group)) return d

      let itemsOpen = [...group.itemsOpen]

      if (includes(itemsOpen, itemId)) {
        itemsOpen = without(itemsOpen, itemId)
      } else if (group.multiOpen) {
        itemsOpen.push(itemId)
      } else {
        itemsOpen = [itemId]
      }

      itemsOpen = compact(itemsOpen)

      return {
        ...d,
        [groupId]: {
          ...group,
          itemsOpen
        }
      }
    })
  }, [])

  //
  // Collapse item
  const collapse = useCallback((groupId: string, itemId: string) => {
    if (!isPresent(groupId)) return
    if (!isPresent(itemId)) return

    const group: CollapsibleGroup = collapsibles[groupId]

    if (includes(group?.itemsOpen || [], itemId)) toggle(groupId, itemId)
  }, [collapsibles, toggle])

  //
  // Uncollapse item
  const uncollapse = useCallback((groupId: string, itemId: string) => {
    if (!isPresent(groupId)) return
    if (!isPresent(itemId)) return

    const group: CollapsibleGroup = collapsibles[groupId]

    if (!includes(group?.itemsOpen || [], itemId)) toggle(groupId, itemId)
  }, [collapsibles, toggle])

  //
  // Ensure a group inside the data structure
  const ensureGroup = useCallback((groupId: string) => {
    if (!isPresent(groupId)) return

    setCollapsibles((d) => {
      if (isObject(d[groupId])) return d

      return {
        ...d,
        [groupId]: {
          id: groupId,
          multiOpen: false,
          itemsOpen: []
        }
      }
    })
  }, [])

  //
  // Build context data
  const data: CollapsibleContext = useMemo(() => ({
    ...DEFAULT_DATA,
    collapsibles,
    toggle,
    collapse,
    uncollapse,
    ensureGroup,
  } as CollapsibleContext), [
    collapse,
    collapsibles,
    ensureGroup,
    toggle,
    uncollapse
  ])

  //
  // Render component
  return (
    <Provider value={data}>
      {children}
    </Provider>
  )
}

export default CollapsibleContextProvider
