import isNumber from 'lodash/isNumber'
import isNaN from 'lodash/isNaN'
import { FC, ReactNode, useMemo } from 'react'
import classnames from 'classnames'
import isPresent from '@vayapin/utils/isPresent'

interface ColProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  offsetXs?: number;
  offsetSm?: number;
  offsetMd?: number;
  offsetLg?: number;
  offsetXl?: number;
  offsetXxl?: number;
  children?: ReactNode;
  className?: string;
}

const Col: FC<ColProps> = ({
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  offsetXs,
  offsetSm,
  offsetMd,
  offsetLg,
  offsetXl,
  offsetXxl,
  children,
  className,
}) => {
  const classes = useMemo(() => classnames({
    'col': true,
    [`col-${xs || ''}`]: isValidNumber(xs),
    [`col-sm-${sm || ''}`]: isValidNumber(sm),
    [`col-md-${md || ''}`]: isValidNumber(md),
    [`col-lg-${lg || ''}`]: isValidNumber(lg),
    [`col-xl-${xl || ''}`]: isValidNumber(xl),
    [`col-xxl-${xxl || ''}`]: isValidNumber(xxl),
    [`offset-${offsetXs || ''}`]: isValidNumber(offsetXs),
    [`offset-sm-${offsetSm || ''}`]: isValidNumber(offsetSm),
    [`offset-md-${offsetMd || ''}`]: isValidNumber(offsetMd),
    [`offset-lg-${offsetLg || ''}`]: isValidNumber(offsetLg),
    [`offset-xl-${offsetXl || ''}`]: isValidNumber(offsetXl),
    [`offset-xxl-${offsetXxl || ''}`]: isValidNumber(offsetXxl),
    [className || '']: isPresent(className),
  }), [
    className,
    lg,
    md,
    offsetLg,
    offsetMd,
    offsetSm,
    offsetXl,
    offsetXs,
    offsetXxl,
    sm,
    xl,
    xs,
    xxl
  ])

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

function isValidNumber(value: unknown): boolean {
  return isNumber(value) && !isNaN(value)
}

export default Col
