import { FC, ReactNode } from 'react'
import { GrClose } from 'react-icons/gr'
import Styles from './ModalHeader.module.sass'

interface ModalHeaderProps {
  disableClose?: boolean;
  title?: ReactNode | string | null,
  onClickClose: () => void,
  closeLabel: ReactNode | string | null
}

const ModalHeader: FC<ModalHeaderProps> = ({
  disableClose = false,
  title = null,
  onClickClose = () => undefined,
  closeLabel = <GrClose />,
}) => (
  <header className={`modal-header ${Styles.header}`}>
    <div className={`${Styles.colIcon}`}>
    </div>
    <div className={Styles.title}>
      {title}
    </div>
    <div className={`${Styles.colIcon}`}>
      <button
        className={Styles.button}
        disabled={disableClose}
        onClick={(e) => {
          e.preventDefault()
          onClickClose()
        }}
      >
        { closeLabel }
      </button>
    </div>
  </header>
)

export default ModalHeader
