import includes from 'lodash/includes'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'

import Context from './CollapsibleContext'
import { CollapsibleGroup } from './Types'

function useCollabsibleItem(groupId: string, itemId: string) {
  const {
    collapsibles,
    toggle,
    ensureGroup,
  } = useContext(Context)

  //
  // Update fix:
  // Since toggle will always be a new function, we're
  // having react component updating issues with leads to wrong
  // behavior. To fix this, we're updating a ref to prevent
  // component updates:
  const toggleRef = useRef<null | typeof toggle>(toggle)
  useEffect(() => {
    toggleRef.current = toggle
    return function cleanup() {
      if (toggleRef?.current) toggleRef.current = null
    }
  }, [toggle])

  //
  // Ensure group data
  useEffect(() => {
    ensureGroup(groupId)
  }, [ensureGroup, groupId])

  //
  // Prepare collapsible
  const group = useMemo(() => {
    const g = collapsibles[groupId]

    return g && isObject(g) ? g : {} as CollapsibleGroup
  }, [collapsibles, groupId])

  //
  // Build id
  const id = useMemo(
    () => `collapsible-${groupId}-${itemId}`,
    [groupId, itemId]
  )

  //
  // Check collapsible state
  const collapsed = useMemo(() => {
    const iOpen = group?.itemsOpen || []
    return !includes(iOpen, itemId)
  }, [group?.itemsOpen, itemId])

  //
  // Toggle function
  const toggleItem = useCallback(() => {
    if (!isFunction(toggleRef?.current)) return

    toggleRef.current(groupId, itemId)
  }, [groupId, itemId])

  //
  // Return
  return useMemo(() => ({
    id,
    collapsed,
    toggle: toggleItem,
  }), [collapsed, id, toggleItem])
}

export default useCollabsibleItem
