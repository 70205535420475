import { FC, ReactNode, useCallback, useMemo } from 'react'
import ReactModal, { Props as ReactModalProps } from 'react-modal'
import classnames from 'classnames'
import ModalHeader from './ModalHeader'
import Styles from './Modal.module.sass'

ReactModal.defaultStyles.overlay = {}
ReactModal.defaultStyles.content = {}

export interface ModalProps extends ReactModalProps {
  disableClose?: boolean;
  showHeader?: boolean;
  noPadding?: boolean;
  contentClassName?: string;
  headerTitle?: ReactNode | string | null;
  footer?: ReactNode | null;
  onClose?: () => void;
  modalHeaderCloseLabel?: ReactNode | string | number | null;
}

const Modal: FC<ModalProps> = ({
  headerTitle = null,
  children = null,
  disableClose = false,
  showHeader = false,
  noPadding = false,
  className = null,
  contentClassName,
  modalHeaderCloseLabel,
  onClose = () => undefined,
  footer,
  ...restProps
}) => {
  const contentClasses = useMemo(() => classnames({
    [Styles.modalContent]: true,
    [Styles.modalContentNoPadding]: noPadding,
    [contentClassName || '']: true,
  }), [contentClassName, noPadding])

  const onAfterClose = useCallback(() => {
    if (restProps.onAfterClose) restProps.onAfterClose()

    document.body.classList.remove('ReactModal__Body--open')
  }, [restProps])

  const onInternalClose = useCallback(() => {
    if (disableClose) return

    onClose()
  }, [disableClose, onClose])

  return (
    // @ts-ignore
    <ReactModal
      {...restProps}
      className={`${Styles.modal} ${(className || '') as string}`}
      onRequestClose={onInternalClose}
      onAfterClose={onAfterClose}
    >
      {showHeader && (
        <ModalHeader
          disableClose={disableClose}
          title={headerTitle}
          onClickClose={onInternalClose}
          closeLabel={modalHeaderCloseLabel}
        />
      )}
      <div className={contentClasses}>
        {/* @ts-ignore */}
        {children}
      </div>
      {!!footer && (
        <div className={Styles.footer}>
          { footer }
        </div>
      )}
    </ReactModal>
  )
}

export default Modal
