import classnames from 'classnames'
import { CSSProperties, FC, ReactNode, useMemo } from 'react'

import Styles from './CardContent.module.sass'

interface CardContentProps {
  className?: string,
  first?: boolean,
  last?: boolean,
  noPadding?: boolean,
  children: ReactNode,

  /**
   * Handle the overflow behavior
   */
  overflow?: CSSProperties['overflow']
}

const CardContent: FC<CardContentProps> = ({
  className,
  first = false,
  last = false,
  noPadding = false,
  overflow = 'hidden',
  children,
}) => {
  const classes = useMemo(() => classnames({
    'card-content': true,
    [Styles.cardContent]: true,
    [Styles.first]: first,
    [Styles.last]: last,
    [Styles.noPadding]: noPadding,
    [Styles.overflowAuto]: overflow === 'auto',
    [Styles.overflowVisible]: overflow === 'visible',
    [Styles.overflowHidden]: overflow === 'hidden',
    [Styles.overflowClip]: overflow === 'clip',
    [Styles.overflowScroll]: overflow === 'scroll',
    [className || '']: true,
  }), [className, first, last, noPadding, overflow])

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export default CardContent
