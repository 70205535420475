import { createContext } from 'react'
import {
  CollapsibleContext as ICollapsibleContext,
  CollapsibleGroup as ICollapsibleGroup,
} from './Types'

export const DEFAULT_DATA: ICollapsibleContext = {
  collapsibles: {},
  toggle: (_groupId: string, _itemId: string) => undefined,
  collapse: (_groupId: string, _itemId: string) => undefined,
  uncollapse: (_groupId: string, _itemId: string) => undefined,
  ensureGroup: (_groupId: string) => ({} as ICollapsibleGroup),
}

const CollapsibleContext = createContext(DEFAULT_DATA)
CollapsibleContext.displayName = 'CollapsibleContext'

export const Provider = CollapsibleContext.Provider
export const Consumer = CollapsibleContext.Consumer

export default CollapsibleContext
