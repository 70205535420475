'use client'

export type {
  CollapsibleContext as ICollapsibleContext,
  CollapsibleGroup as ICollapsibleGroup,
  CollapsibleProps,
  CollapsibleItemProps,
} from './Types'

export { default as CollapsibleContext } from './CollapsibleContext'
export { default as CollapsibleContextProvider } from './CollapsibleContextProvider'
export {
  Collapsible,
  CollapsibleItem,
} from './components'
export { default as useCollabsible } from './useCollabsible'
export { default as useCollabsibleItem } from './useCollabsibleItem'
