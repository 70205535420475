import { FC, useMemo } from 'react'
import classnames from 'classnames'
import { FaChevronRight } from 'react-icons/fa'
import {
  CollapsibleItemProps,
  useCollabsibleItem,
} from 'services/Collapsible'
import Styles from './PinDataCollapsibleItemLabel.module.sass'

export interface PinDataCollapsibleItemLabelProps {
  id: CollapsibleItemProps['id'];
  groupId: CollapsibleItemProps['groupId'];
  label: CollapsibleItemProps['label'];
  last?: boolean;
}

const PinDataCollapsibleItemLabel: FC<PinDataCollapsibleItemLabelProps> = ({
  groupId,
  id,
  label,
  last = false,
}) => {
  const { collapsed } = useCollabsibleItem(groupId, id)

  const wrapperClasses = useMemo(() => classnames({
    [Styles.wrapper]: true,
    [Styles.wrapperCollapsed]: collapsed,
    [Styles.wrapperLast]: last,
  }), [collapsed, last])

  return (
    <div className={wrapperClasses}>
      {label}
      <div className={Styles.icon}>
        <FaChevronRight />
      </div>
    </div>
  )
}

export default PinDataCollapsibleItemLabel
