import RCForm, { FormInstance, FormProps as RCFormProps } from 'rc-field-form'
import {
  DetailedHTMLProps, FC, FormHTMLAttributes, memo, ReactNode,
} from 'react'

import FormContext from './FormContext'

export type HTMLFormComponentProps = DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>
export const List = RCForm.List

export interface FormProps extends HTMLFormComponentProps {
  form: FormInstance,
  children?: ReactNode,
  validateMessages?: RCFormProps['validateMessages'];
  onFormChange?: RCFormProps['onChange'];
  onFormFinish?: RCFormProps['onFinish'];
  onFormValuesChange?: RCFormProps['onValuesChange'];
  onFormFieldsChange?: RCFormProps['onFieldsChange'];
}

const Form: FC<FormProps> = memo(({
  form,
  children,
  validateMessages,
  onFormChange,
  onFormFinish,
  onFormValuesChange,
  onFormFieldsChange,
  ...restProps
}) => (
  <FormContext.Provider value={form}>
    <RCForm
      form={form}
      validateMessages={validateMessages}
      onValuesChange={onFormValuesChange}
      onFieldsChange={onFormFieldsChange}
      onChange={onFormChange}
      onFinish={onFormFinish}
      component={(props) => (
        <HTMLFormComponent {...props} {...restProps} />
      )}
    >
      {children}
    </RCForm>
  </FormContext.Provider>
))
Form.displayName = 'Form'

const HTMLFormComponent: FC<HTMLFormComponentProps> = ({
  children,
  ...restProps
}) => (
  <form {...restProps}>
    {children}
  </form>
)

export default Form
