import classnames from 'classnames'
import {
  type ButtonHTMLAttributes, type MouseEventHandler, type AnchorHTMLAttributes,
  ForwardedRef, forwardRef, ReactNode, useMemo,
} from 'react'

import Styles from './Button.module.sass'

export type ButtonTheme = 'default'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'outline'
  | 'outline-primary'
  | 'outline-secondary'
  | 'outline-info'
  | 'link'
  | 'danger'
  | null
type ButtonType = 'default' | 'link' | 'submit' | null
type ButtonSize = 'default' | 'large' | 'small'
type LinkClickEventHandler = MouseEventHandler<HTMLAnchorElement> | undefined
type ButtonClickEventHandler = MouseEventHandler<HTMLButtonElement> |
undefined

export interface ButtonWrapperProps {
  type?: ButtonType;
  href?: string | null;
  children?: ReactNode | null;
  className?: string,
  disabled?: boolean;
  onClick?: LinkClickEventHandler | ButtonClickEventHandler;
}

export interface BaseButtonProps extends ButtonWrapperProps {
  theme?: ButtonTheme;
  size?: ButtonSize;
  block?: boolean;
}

declare const ButtonHTMLTypes: ['submit', 'button', 'reset']

export declare type ButtonHTMLType = typeof ButtonHTMLTypes[number]

export declare type NativeButtonProps = {
  onClick?: MouseEventHandler<HTMLElement>;
} & Omit<
  ButtonHTMLAttributes<unknown>, 'onClick' | 'type'
> & BaseButtonProps

export declare type AnchorButtonProps = {
  href: string;
  target?: string;
  onClick?: MouseEventHandler<HTMLElement>;
} & Omit<
  AnchorHTMLAttributes<unknown>, 'onClick' | 'type'
> & BaseButtonProps

export declare type ButtonProps = Partial<AnchorButtonProps & NativeButtonProps>

export type ButtonWrapperType = HTMLButtonElement | HTMLAnchorElement

const Button = forwardRef<ButtonWrapperType, ButtonProps>(({
  theme = 'default',
  size = 'default',
  block = false,
  disabled = false,
  className,
  children,
  ...restProps
}, ref) => {
  const buttonClasses = useMemo(() => classnames({
    'button': true,
    [Styles.button]: true,
    [Styles.buttonPrimary]: theme === 'primary',
    [Styles.buttonSecondary]: theme === 'secondary',
    [Styles.buttonInfo]: theme === 'info',
    [Styles.buttonOutline]: theme === 'outline',
    [Styles.buttonDanger]: theme === 'danger',
    [Styles.buttonOutlinePrimary]: theme === 'outline-primary',
    [Styles.buttonOutlineSecondary]: theme === 'outline-secondary',
    [Styles.buttonOutlineInfo]: theme === 'outline-info',
    [Styles.buttonLink]: theme === 'link',
    [Styles.buttonBlock]: block,
    [Styles.buttonDisabled]: disabled,
    [Styles.buttonSmall]: size === 'small',
    [Styles.buttonLarge]: size === 'large',
    [className || '']: true,
  }), [block, className, disabled, size, theme])

  return (
    <ButtonWrapper
      className={buttonClasses}
      disabled={disabled}
      ref={ref}
      {...restProps}
    >
      <div className={Styles.buttonInside}>
        {children}
      </div>
    </ButtonWrapper>
  )
})
Button.displayName = 'Button'

const ButtonWrapper = forwardRef<ButtonWrapperType, ButtonWrapperProps>(({
  type = 'default',
  href = '#',
  onClick,
  children,
  ...restProps
}, ref) => {
  if (type === 'submit') {
    return (
      <button
        onClick={onClick as ButtonClickEventHandler}
        type="submit"
        ref={ref as ForwardedRef<HTMLButtonElement>}
        {...restProps}
      >
        {children}
      </button>
    )
  } else if (type === 'link') {
    return (
      <a
        onClick={onClick as LinkClickEventHandler}
        href={href || '#'}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        {...restProps}
      >
        {children}
      </a>
    )
  } else {
    return (
      <button
        onClick={onClick as ButtonClickEventHandler}
        type="button"
        ref={ref as ForwardedRef<HTMLButtonElement>}
        {...restProps}
      >
        {children}
      </button>
    )
  }
})
ButtonWrapper.displayName = 'ButtonWrapper'

export default Button
