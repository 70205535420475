import isArray from 'lodash/isArray'
import isEqual from 'lodash/isEqual'
import { FC, useEffect, useMemo, useRef } from 'react'
import { CollapsibleProps } from '../Types'
import useCollabsible from '../useCollabsible'

const Collapsible: FC<CollapsibleProps> = ({
  groupId,
  activeIds = [],
  children,
}) => {
  const { uncollapse } = useCollabsible(groupId)

  //
  // Array reference hack. The issue is, that most likely,
  // the `activeIds` variable will always be a new memory
  // reference although the content might be the same. To
  // address this, we're checking if the content actually
  // changed. Only if so, `aIds` will get the new value
  // of `activeIds`.
  const activeIdsRef = useRef<string[]>(
    (isArray(activeIds) ? activeIds : [activeIds]) || []
  )
  const aIds = useMemo(() => {
    const ids = isArray(activeIds) ? activeIds : [activeIds]

    return isEqual(activeIdsRef.current, ids)
      ? activeIdsRef.current
      : ids
  }, [activeIds])

  //
  // Uncollapse selected items
  useEffect(() => {
    activeIdsRef.current = aIds
    aIds.forEach((id) => uncollapse(id || ''))
  }, [aIds, uncollapse])

  //
  // Render
  return (
    <>
      {children}
    </>
  )
}

export default Collapsible
