import RCForm, { FormInstance } from 'rc-field-form'

export type { FieldProps } from 'rc-field-form/es/Field'
export { default } from './Form'
export type { FormContextData } from './FormContext'
export type { ComponentFieldBaseProps as WithFieldComponentBaseProps } from './Types'
export { default as withField } from './withField'

export const Field = RCForm.Field

export function useForm<Values = unknown>(
  form?: FormInstance<Values>
): FormInstance<Values> {
  return RCForm.useForm<Values>(form)[0]
}
