import { LoadingIndicator } from 'atoms'
import { FC, ReactNode } from 'react'

import Styles from './LoadingWrapper.module.sass'

interface LoadingWrapperProps {
  label?: ReactNode | string | null,
  loading?: boolean,
  children?: ReactNode,
}

const LoadingWrapper: FC<LoadingWrapperProps> = ({
  label = null,
  loading = false,
  children,
}) => (
  <div className={Styles.wrapper}>
    {loading && (
      <div className={Styles.overlay}>
        <LoadingIndicator dark />
        {label && (
          <div className={Styles.label}>
            {label}
          </div>
        )}
      </div>
    )}
    {children}
  </div>
)

export default LoadingWrapper
