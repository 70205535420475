import {
  FC,
  useMemo,
  ReactElement,
  JSXElementConstructor,
} from 'react'
import Image, { StaticImageData } from 'next/image'
import getFlag from '~/util/getFlag'

type FlagReturn = ReactElement<unknown, JSXElementConstructor<unknown>> | null

interface FlagProps {
  /**
   * Country code in ISO-3166-1 format
   */
  country?: string,

  /**
   * Flag image ratio. Will be ignored if height is set.
   * Default: 1.6
   */
  ratio?: number,

  /**
   * Flag width. Default: 20
   */
  width?: number,

  /**
   * Flag height. Will by calculated by width and ratio if not set.
   * Default: undefined
   */
  height?: number,

   /**
    * Fallback element to render if flag has not been found.
    * Default: null
    */
  fallback?: FlagReturn,
}

const Flag: FC<FlagProps> = ({
  country,
  width = 20,
  height,
  ratio = 1.6,
  fallback = null,
  ...restProps
}): FlagReturn => {
  const imgUrl: StaticImageData | undefined = useMemo(
    () => getFlag(country) as StaticImageData,
    [country]
  )

  const calcHeight = useMemo(() => {
    if (height) return height
    return width / ratio
  }, [height, ratio, width])

  return imgUrl ? (
    <Image
      alt={country || ''}
      {...restProps}
      src={imgUrl}
      width={width}
      height={calcHeight}
    />
  ) : fallback
}

export default Flag
