import classNames from 'classnames'
import { FC, useMemo } from 'react'

import Styles from './LoadingIndicator.module.sass'

export interface LoadingIndicatorProps {
  dark?: boolean;
}

const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  dark = false,
}) => {
  const classes = useMemo(() => classNames({
    [Styles.spinner]: true,
    [Styles.spinnerDark]: dark,
  }), [dark])

  return (
    <div className={classes}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default LoadingIndicator
