import type { StaticImageData } from 'next/image'
import has from 'lodash/has'
import isPresent from '@vayapin/utils/isPresent'

import ad from 'svg-country-flags/svg/ad.svg'
import ae from 'svg-country-flags/svg/ae.svg'
import af from 'svg-country-flags/svg/af.svg'
import ag from 'svg-country-flags/svg/ag.svg'
import ai from 'svg-country-flags/svg/ai.svg'
import al from 'svg-country-flags/svg/al.svg'
import am from 'svg-country-flags/svg/am.svg'
import an from 'svg-country-flags/svg/an.svg'
import ao from 'svg-country-flags/svg/ao.svg'
import aq from 'svg-country-flags/svg/aq.svg'
import ar from 'svg-country-flags/svg/ar.svg'
import as from 'svg-country-flags/svg/as.svg'
import at from 'svg-country-flags/svg/at.svg'
import au from 'svg-country-flags/svg/au.svg'
import aw from 'svg-country-flags/svg/aw.svg'
import ax from 'svg-country-flags/svg/ax.svg'
import az from 'svg-country-flags/svg/az.svg'
import ba from 'svg-country-flags/svg/ba.svg'
import bb from 'svg-country-flags/svg/bb.svg'
import bd from 'svg-country-flags/svg/bd.svg'
import be from 'svg-country-flags/svg/be.svg'
import bf from 'svg-country-flags/svg/bf.svg'
import bg from 'svg-country-flags/svg/bg.svg'
import bh from 'svg-country-flags/svg/bh.svg'
import bi from 'svg-country-flags/svg/bi.svg'
import bj from 'svg-country-flags/svg/bj.svg'
import bl from 'svg-country-flags/svg/bl.svg'
import bm from 'svg-country-flags/svg/bm.svg'
import bn from 'svg-country-flags/svg/bn.svg'
import bo from 'svg-country-flags/svg/bo.svg'
import bq from 'svg-country-flags/svg/bq.svg'
import br from 'svg-country-flags/svg/br.svg'
import bs from 'svg-country-flags/svg/bs.svg'
import bt from 'svg-country-flags/svg/bt.svg'
import bv from 'svg-country-flags/svg/bv.svg'
import bw from 'svg-country-flags/svg/bw.svg'
import by from 'svg-country-flags/svg/by.svg'
import bz from 'svg-country-flags/svg/bz.svg'
import ca from 'svg-country-flags/svg/ca.svg'
import cc from 'svg-country-flags/svg/cc.svg'
import cd from 'svg-country-flags/svg/cd.svg'
import cf from 'svg-country-flags/svg/cf.svg'
import cg from 'svg-country-flags/svg/cg.svg'
import ch from 'svg-country-flags/svg/ch.svg'
import ci from 'svg-country-flags/svg/ci.svg'
import ck from 'svg-country-flags/svg/ck.svg'
import cl from 'svg-country-flags/svg/cl.svg'
import cm from 'svg-country-flags/svg/cm.svg'
import cn from 'svg-country-flags/svg/cn.svg'
import co from 'svg-country-flags/svg/co.svg'
import cr from 'svg-country-flags/svg/cr.svg'
import cu from 'svg-country-flags/svg/cu.svg'
import cv from 'svg-country-flags/svg/cv.svg'
import cw from 'svg-country-flags/svg/cw.svg'
import cx from 'svg-country-flags/svg/cx.svg'
import cy from 'svg-country-flags/svg/cy.svg'
import cz from 'svg-country-flags/svg/cz.svg'
import de from 'svg-country-flags/svg/de.svg'
import dj from 'svg-country-flags/svg/dj.svg'
import dk from 'svg-country-flags/svg/dk.svg'
import dm from 'svg-country-flags/svg/dm.svg'
import doFile from 'svg-country-flags/svg/do.svg'
import dz from 'svg-country-flags/svg/dz.svg'
import ec from 'svg-country-flags/svg/ec.svg'
import ee from 'svg-country-flags/svg/ee.svg'
import eg from 'svg-country-flags/svg/eg.svg'
import eh from 'svg-country-flags/svg/eh.svg'
import er from 'svg-country-flags/svg/er.svg'
import es from 'svg-country-flags/svg/es.svg'
import et from 'svg-country-flags/svg/et.svg'
import eu from 'svg-country-flags/svg/eu.svg'
import fi from 'svg-country-flags/svg/fi.svg'
import fj from 'svg-country-flags/svg/fj.svg'
import fk from 'svg-country-flags/svg/fk.svg'
import fm from 'svg-country-flags/svg/fm.svg'
import fo from 'svg-country-flags/svg/fo.svg'
import fr from 'svg-country-flags/svg/fr.svg'
import ga from 'svg-country-flags/svg/ga.svg'
import gb from 'svg-country-flags/svg/gb.svg'
import gd from 'svg-country-flags/svg/gd.svg'
import ge from 'svg-country-flags/svg/ge.svg'
import gf from 'svg-country-flags/svg/gf.svg'
import gg from 'svg-country-flags/svg/gg.svg'
import gh from 'svg-country-flags/svg/gh.svg'
import gi from 'svg-country-flags/svg/gi.svg'
import gl from 'svg-country-flags/svg/gl.svg'
import gm from 'svg-country-flags/svg/gm.svg'
import gn from 'svg-country-flags/svg/gn.svg'
import gp from 'svg-country-flags/svg/gp.svg'
import gq from 'svg-country-flags/svg/gq.svg'
import gr from 'svg-country-flags/svg/gr.svg'
import gs from 'svg-country-flags/svg/gs.svg'
import gt from 'svg-country-flags/svg/gt.svg'
import gu from 'svg-country-flags/svg/gu.svg'
import gw from 'svg-country-flags/svg/gw.svg'
import gy from 'svg-country-flags/svg/gy.svg'
import hk from 'svg-country-flags/svg/hk.svg'
import hm from 'svg-country-flags/svg/hm.svg'
import hn from 'svg-country-flags/svg/hn.svg'
import hr from 'svg-country-flags/svg/hr.svg'
import ht from 'svg-country-flags/svg/ht.svg'
import hu from 'svg-country-flags/svg/hu.svg'
import id from 'svg-country-flags/svg/id.svg'
import ie from 'svg-country-flags/svg/ie.svg'
import il from 'svg-country-flags/svg/il.svg'
import im from 'svg-country-flags/svg/im.svg'
import inFile from 'svg-country-flags/svg/in.svg'
import io from 'svg-country-flags/svg/io.svg'
import iq from 'svg-country-flags/svg/iq.svg'
import ir from 'svg-country-flags/svg/ir.svg'
import is from 'svg-country-flags/svg/is.svg'
import it from 'svg-country-flags/svg/it.svg'
import je from 'svg-country-flags/svg/je.svg'
import jm from 'svg-country-flags/svg/jm.svg'
import jo from 'svg-country-flags/svg/jo.svg'
import jp from 'svg-country-flags/svg/jp.svg'
import ke from 'svg-country-flags/svg/ke.svg'
import kg from 'svg-country-flags/svg/kg.svg'
import kh from 'svg-country-flags/svg/kh.svg'
import ki from 'svg-country-flags/svg/ki.svg'
import km from 'svg-country-flags/svg/km.svg'
import kn from 'svg-country-flags/svg/kn.svg'
import kp from 'svg-country-flags/svg/kp.svg'
import kr from 'svg-country-flags/svg/kr.svg'
import kw from 'svg-country-flags/svg/kw.svg'
import ky from 'svg-country-flags/svg/ky.svg'
import kz from 'svg-country-flags/svg/kz.svg'
import la from 'svg-country-flags/svg/la.svg'
import lb from 'svg-country-flags/svg/lb.svg'
import lc from 'svg-country-flags/svg/lc.svg'
import li from 'svg-country-flags/svg/li.svg'
import lk from 'svg-country-flags/svg/lk.svg'
import lr from 'svg-country-flags/svg/lr.svg'
import ls from 'svg-country-flags/svg/ls.svg'
import lt from 'svg-country-flags/svg/lt.svg'
import lu from 'svg-country-flags/svg/lu.svg'
import lv from 'svg-country-flags/svg/lv.svg'
import ly from 'svg-country-flags/svg/ly.svg'
import ma from 'svg-country-flags/svg/ma.svg'
import mc from 'svg-country-flags/svg/mc.svg'
import md from 'svg-country-flags/svg/md.svg'
import me from 'svg-country-flags/svg/me.svg'
import mf from 'svg-country-flags/svg/mf.svg'
import mg from 'svg-country-flags/svg/mg.svg'
import mh from 'svg-country-flags/svg/mh.svg'
import mk from 'svg-country-flags/svg/mk.svg'
import ml from 'svg-country-flags/svg/ml.svg'
import mm from 'svg-country-flags/svg/mm.svg'
import mn from 'svg-country-flags/svg/mn.svg'
import mo from 'svg-country-flags/svg/mo.svg'
import mp from 'svg-country-flags/svg/mp.svg'
import mq from 'svg-country-flags/svg/mq.svg'
import mr from 'svg-country-flags/svg/mr.svg'
import ms from 'svg-country-flags/svg/ms.svg'
import mt from 'svg-country-flags/svg/mt.svg'
import mu from 'svg-country-flags/svg/mu.svg'
import mv from 'svg-country-flags/svg/mv.svg'
import mw from 'svg-country-flags/svg/mw.svg'
import mx from 'svg-country-flags/svg/mx.svg'
import my from 'svg-country-flags/svg/my.svg'
import mz from 'svg-country-flags/svg/mz.svg'
import na from 'svg-country-flags/svg/na.svg'
import nc from 'svg-country-flags/svg/nc.svg'
import ne from 'svg-country-flags/svg/ne.svg'
import nf from 'svg-country-flags/svg/nf.svg'
import ng from 'svg-country-flags/svg/ng.svg'
import ni from 'svg-country-flags/svg/ni.svg'
import nl from 'svg-country-flags/svg/nl.svg'
import no from 'svg-country-flags/svg/no.svg'
import np from 'svg-country-flags/svg/np.svg'
import nr from 'svg-country-flags/svg/nr.svg'
import nu from 'svg-country-flags/svg/nu.svg'
import nz from 'svg-country-flags/svg/nz.svg'
import om from 'svg-country-flags/svg/om.svg'
import pa from 'svg-country-flags/svg/pa.svg'
import pe from 'svg-country-flags/svg/pe.svg'
import pf from 'svg-country-flags/svg/pf.svg'
import pg from 'svg-country-flags/svg/pg.svg'
import ph from 'svg-country-flags/svg/ph.svg'
import pk from 'svg-country-flags/svg/pk.svg'
import pl from 'svg-country-flags/svg/pl.svg'
import pm from 'svg-country-flags/svg/pm.svg'
import pn from 'svg-country-flags/svg/pn.svg'
import pr from 'svg-country-flags/svg/pr.svg'
import ps from 'svg-country-flags/svg/ps.svg'
import pt from 'svg-country-flags/svg/pt.svg'
import pw from 'svg-country-flags/svg/pw.svg'
import py from 'svg-country-flags/svg/py.svg'
import qa from 'svg-country-flags/svg/qa.svg'
import re from 'svg-country-flags/svg/re.svg'
import ro from 'svg-country-flags/svg/ro.svg'
import rs from 'svg-country-flags/svg/rs.svg'
import ru from 'svg-country-flags/svg/ru.svg'
import rw from 'svg-country-flags/svg/rw.svg'
import sa from 'svg-country-flags/svg/sa.svg'
import sb from 'svg-country-flags/svg/sb.svg'
import sc from 'svg-country-flags/svg/sc.svg'
import sd from 'svg-country-flags/svg/sd.svg'
import se from 'svg-country-flags/svg/se.svg'
import sg from 'svg-country-flags/svg/sg.svg'
import sh from 'svg-country-flags/svg/sh.svg'
import si from 'svg-country-flags/svg/si.svg'
import sj from 'svg-country-flags/svg/sj.svg'
import sk from 'svg-country-flags/svg/sk.svg'
import sl from 'svg-country-flags/svg/sl.svg'
import sm from 'svg-country-flags/svg/sm.svg'
import sn from 'svg-country-flags/svg/sn.svg'
import so from 'svg-country-flags/svg/so.svg'
import sr from 'svg-country-flags/svg/sr.svg'
import ss from 'svg-country-flags/svg/ss.svg'
import st from 'svg-country-flags/svg/st.svg'
import sv from 'svg-country-flags/svg/sv.svg'
import sx from 'svg-country-flags/svg/sx.svg'
import sy from 'svg-country-flags/svg/sy.svg'
import sz from 'svg-country-flags/svg/sz.svg'
import tc from 'svg-country-flags/svg/tc.svg'
import td from 'svg-country-flags/svg/td.svg'
import tf from 'svg-country-flags/svg/tf.svg'
import tg from 'svg-country-flags/svg/tg.svg'
import th from 'svg-country-flags/svg/th.svg'
import tj from 'svg-country-flags/svg/tj.svg'
import tk from 'svg-country-flags/svg/tk.svg'
import tl from 'svg-country-flags/svg/tl.svg'
import tm from 'svg-country-flags/svg/tm.svg'
import tn from 'svg-country-flags/svg/tn.svg'
import to from 'svg-country-flags/svg/to.svg'
import tr from 'svg-country-flags/svg/tr.svg'
import tt from 'svg-country-flags/svg/tt.svg'
import tv from 'svg-country-flags/svg/tv.svg'
import tw from 'svg-country-flags/svg/tw.svg'
import tz from 'svg-country-flags/svg/tz.svg'
import ua from 'svg-country-flags/svg/ua.svg'
import ug from 'svg-country-flags/svg/ug.svg'
import um from 'svg-country-flags/svg/um.svg'
import us from 'svg-country-flags/svg/us.svg'
import uy from 'svg-country-flags/svg/uy.svg'
import uz from 'svg-country-flags/svg/uz.svg'
import va from 'svg-country-flags/svg/va.svg'
import vc from 'svg-country-flags/svg/vc.svg'
import ve from 'svg-country-flags/svg/ve.svg'
import vg from 'svg-country-flags/svg/vg.svg'
import vi from 'svg-country-flags/svg/vi.svg'
import vn from 'svg-country-flags/svg/vn.svg'
import vu from 'svg-country-flags/svg/vu.svg'
import wf from 'svg-country-flags/svg/wf.svg'
import ws from 'svg-country-flags/svg/ws.svg'
import xk from 'svg-country-flags/svg/xk.svg'
import ye from 'svg-country-flags/svg/ye.svg'
import yt from 'svg-country-flags/svg/yt.svg'
import za from 'svg-country-flags/svg/za.svg'
import zm from 'svg-country-flags/svg/zm.svg'
import zw from 'svg-country-flags/svg/zw.svg'

const map = {
  ad,
  ae,
  af,
  ag,
  ai,
  al,
  am,
  an,
  ao,
  aq,
  ar,
  as,
  at,
  au,
  aw,
  ax,
  az,
  ba,
  bb,
  bd,
  be,
  bf,
  bg,
  bh,
  bi,
  bj,
  bl,
  bm,
  bn,
  bo,
  bq,
  br,
  bs,
  bt,
  bv,
  bw,
  by,
  bz,
  ca,
  cc,
  cd,
  cf,
  cg,
  ch,
  ci,
  ck,
  cl,
  cm,
  cn,
  co,
  cr,
  cu,
  cv,
  cw,
  cx,
  cy,
  cz,
  de,
  dj,
  dk,
  dm,
  do: doFile,
  dz,
  ec,
  ee,
  eg,
  eh,
  er,
  es,
  et,
  eu,
  fi,
  fj,
  fk,
  fm,
  fo,
  fr,
  ga,
  gb,
  gd,
  ge,
  gf,
  gg,
  gh,
  gi,
  gl,
  gm,
  gn,
  gp,
  gq,
  gr,
  gs,
  gt,
  gu,
  gw,
  gy,
  hk,
  hm,
  hn,
  hr,
  ht,
  hu,
  id,
  ie,
  il,
  im,
  in: inFile,
  io,
  iq,
  ir,
  is,
  it,
  je,
  jm,
  jo,
  jp,
  ke,
  kg,
  kh,
  ki,
  km,
  kn,
  kp,
  kr,
  kw,
  ky,
  kz,
  la,
  lb,
  lc,
  li,
  lk,
  lr,
  ls,
  lt,
  lu,
  lv,
  ly,
  ma,
  mc,
  md,
  me,
  mf,
  mg,
  mh,
  mk,
  ml,
  mm,
  mn,
  mo,
  mp,
  mq,
  mr,
  ms,
  mt,
  mu,
  mv,
  mw,
  mx,
  my,
  mz,
  na,
  nc,
  ne,
  nf,
  ng,
  ni,
  nl,
  no,
  np,
  nr,
  nu,
  nz,
  om,
  pa,
  pe,
  pf,
  pg,
  ph,
  pk,
  pl,
  pm,
  pn,
  pr,
  ps,
  pt,
  pw,
  py,
  qa,
  re,
  ro,
  rs,
  ru,
  rw,
  sa,
  sb,
  sc,
  sd,
  se,
  sg,
  sh,
  si,
  sj,
  sk,
  sl,
  sm,
  sn,
  so,
  sr,
  ss,
  st,
  sv,
  sx,
  sy,
  sz,
  tc,
  td,
  tf,
  tg,
  th,
  tj,
  tk,
  tl,
  tm,
  tn,
  to,
  tr,
  tt,
  tv,
  tw,
  tz,
  ua,
  ug,
  um,
  us,
  uy,
  uz,
  va,
  vc,
  ve,
  vg,
  vi,
  vn,
  vu,
  wf,
  ws,
  xk,
  ye,
  yt,
  za,
  zm,
  zw,
} as { [key: string]: StaticImageData }

function getFlag(
  iso3166?: string | null
): StaticImageData | null {
  if (!iso3166 || !isPresent(iso3166)) return null
  if (!has(map, iso3166.toLowerCase())) return null
  return map[iso3166.toLowerCase()]
}

export default getFlag
