import type { CollapsibleItemProps } from 'services/Collapsible'
import { FC } from 'react'
import { CollapsibleItem } from 'atoms'
import PinDataCollapsibleItemLabel from './PinDataCollapsibleItemLabel'
import PinDataCollapsibleItemContent from './PinDataCollapsibleItemContent'
import Styles from './PinDataCollapsibleItem.module.sass'

export interface PinDataCollapsibleItemProps extends CollapsibleItemProps {
  last?: boolean;
}

const PinDataCollapsibleItem: FC<PinDataCollapsibleItemProps> = ({
  id,
  groupId,
  label,
  content,
  className,
  last = false,
  ...restProps
}) => (
  <CollapsibleItem
    id={id}
    groupId={groupId}
    className={`${Styles.item} ${className || ''}`}
    {...restProps}
    label={(
      <PinDataCollapsibleItemLabel
        id={id}
        groupId={groupId}
        label={label}
        last={last}
      />
    )}
    content={(
      <PinDataCollapsibleItemContent>
        {content}
      </PinDataCollapsibleItemContent>
    )}
  />
)

export default PinDataCollapsibleItem
