import { FC, ReactNode } from 'react'
import Styles from './PinDataCollapsibleItemContent.module.sass'

export interface PinDataCollapsibleItemContentProps {
  children?: ReactNode;
}

const PinDataCollapsibleItemContent: FC<PinDataCollapsibleItemContentProps> = ({
  children,
}) => (
  <div className={Styles.content}>
    {children}
  </div>
)

export default PinDataCollapsibleItemContent
